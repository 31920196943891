/* eslint-disable import/prefer-default-export */

/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const VietLpSuccess = {
  title: "success stories",
  subTitle: "Our merchant",
};

export const VietLPHero = {
  title: "Cơ Hội Kinh Danh cùng SpotOn",
  subtext:
    "Kiếm thu nhập thay đổi cuộc sống trong khi giúp các doanh nghiệp địa phương trong cộng đồng của bạn thành công với các giải pháp kinh doanh sáng tạo của SpotOn.",
  heroImg: "viet-hero.png",
  imageClassName: "fixed-height-600",
  PrimaryCta: {
    ctaTitle: "Đăng Ký Ngay",
    ctaTarget: "/lp/vietnamese-lp/#viet-form",
  },
  fatTitle: "",
  fatTitleClass: "font-large",
};

export const vietTrusted = [
  {
    title: "$628 million",
    subtext: "Financial backing",
  },
  {
    title: "$3.15 billion",
    subtext: "SpotOn valuation",
  },
  {
    title: "6 location",
    subtext: "Increase in purchase of new services with loyal customers",
  },
  {
    title: "1,200+",
    subtext: "Happy employees",
  },
];

export const vietEvents = {
  title: "Tham gia sự kiện Live Event",
  link: {
    ctaTitle: "Đăng ký ở đây",
    ctaTarget: "/lp/vietnamese-lp/#viet-form",
  },
  events: [
    {
      eventTitle: "Sự Kiện ở San Jose, CA",
      eventSub:
        "Hãy sẵn sàng phát triển cùng SpotOnVietnamese, Your Life, Your Business & Your Way",
      eventDate: "25 Jan 2022 /",
      eventTime: " 1pm - 5pm",
      eventLocal: "San Jose, CA",
    },
    {
      eventTitle: "Sự Kiện ở Orange County, CA",
      eventSub:
        "Hãy sẵn sàng phát triển cùng SpotOnVietnamese, Your Life, Your Business & Your Way",
      eventDate: "27 Jan 2022 /",
      eventTime: " 1pm - 5pm",
      eventLocal: "Orange County, CA",
    },
  ],
  image: "viet-event-hero.png",
};
export const VietLPWhiteGlove = {
  mainTitle: "Giới thiệu về SpotOn",
  title: "Được thúc đẩy bởi trái tim và hối hả",
  content:
    "Các doanh nghiệp nhỏ là trái tim và linh hồn của cộng đồng chúng ta. SpotOn được xây dựng để giúp họ cạnh tranh và giành chiến thắng nhờ công nghệ dễ sử dụng, định giá hợp lý và hỗ trợ địa phương từ Người điều hành tài khoản của chúng tôi. Đó là nơi bạn đến.",
  ctaInfoPrimary: {
    ctaTitle: "Tìm hiểu thêm",
    ctaTarget: "/lp/vietnamese-lp/#viet-form",
  },
};

export const VietLPLargeFeatures = {
  featureBlocks: [
    {
      blockTitle: "Khám phá bộ sản phẩm SpotOn",
      blockImg: "viet-lp-1.png",
      blockSubTitle:
        "Các doanh nghiệp nhỏ là trái tim và linh hồn của cộng đồng chúng ta. SpotOn được xây dựng để giúp họ cạnh tranh và giành chiến thắng nhờ công nghệ dễ sử dụng, định giá hợp lý và hỗ trợ địa phương từ Người điều hành tài khoản của chúng tôi. Đó là nơi bạn đến.",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Tìm hiểu thêm",
        ctaTarget: "/lp/vietnamese-lp/#viet-form",
      },
    },
  ],
};
