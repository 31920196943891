import React from "react";
import loadable from "@loadable/component";
import ogImage from "../../../images/global_assets/og-image.png";
import LpLayout from "../../../components/Layout/lp-layout";
import SEO from "../../../components/seo";
import Hero from "../../../components/Hero/hero";
import {
  VietLPHero,
  vietTrusted,
  VietLPWhiteGlove,
  VietLPLargeFeatures,
  vietEvents,
} from "../../../data/landing/viet";

// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import SpecialEvents from "../../../components/SpecialtyComps/SpecialEvents";
// import VietForm from "../../../components/VietForm/vietform";

const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const SpecialEvents = loadable(() =>
  import("../../../components/SpecialtyComps/SpecialEvents")
);
const VietForm = loadable(() =>
  import("../../../components/VietForm/vietform")
);

const whiteGloveImport = "viet-white-glove.png";

const Viet = () => {
  return (
    <LpLayout phoneNumber="+15088476815" phoneText="(508) 847 - 6815">
      <SEO
        title="SpotOn Vietnamese Team | Join the Team"
        description="Join out Vientnamese Team and become a aprt for the fastest growing startup in the fintech infustry"
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <Hero sectionData={VietLPHero} circleBg={false} customTop={0} />
      <section style={{ marginTop: 60 }}>
        <TrustedNumbers numbersArray={vietTrusted} />
      </section>
      <WhiteGlove
        sectionData={VietLPWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={VietLPLargeFeatures}
        style={{ marginTop: "60px" }}
      />
      <SpecialEvents
        title={vietEvents.title}
        events={vietEvents.events}
        image={vietEvents.image}
        link={vietEvents.link}
      />
      <section id="viet-form">
        <VietForm />
      </section>
    </LpLayout>
  );
};

export default Viet;
